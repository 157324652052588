// Remplacez la valeur UA-XXXXXX-Y par l'identifiant analytics de votre site.
var gaProperty = 'UA-25369914-12';
// Désactive le tracking si le cookie d'Opt-out existe déjà
var disableStr = 'ga-disable-' + gaProperty;
if (document.cookie.indexOf('hasConsent=false') > -1) window[disableStr] = true;
//Cette fonction retourne la date d.expiration du cookie de consentement 
function getCookieExpireDate() { 
  var cookieTimeout = 34214400000; // Le nombre de millisecondes que font 13 mois 
  var date = new Date();
  date.setTime(date.getTime()+cookieTimeout);
  var expires = "; expires="+date.toGMTString();
  return expires;
}
// Cette fonction est appelée pour afficher la demande de consentement
function askConsent(){
	console.log('askcontent');
  var bodytag = document.getElementsByTagName('body')[0];
  var div = document.createElement('div');
  div.setAttribute('id','cookie-banner');
  // Le code HTML de la demande de consentement
  // Vous pouvez modifier le contenu ainsi que le style
  div.innerHTML =  '<div style="background-color:transparent;color:#fff">Ce site utilise Google Analytics.\
  En continuant à naviguer, vous nous autorisez à déposer des cookies à des fins de \
  mesure d\'audience. Vous pouvez vous <a href="#optout" onclick="gaOptout()" class="btn btn-default btn-sm">opposer à ce dépôt</a> ou <a href="#optin" onclick="gaOptIn(); document.getElementById(\'cookie-banner\').style.display=\'none\'" class="btn btn-default btn-sm">ignorer ce message</a>.</div>';          
  bodytag.insertBefore(div,bodytag.firstChild); // Ajoute la bannière juste au début de la page 
  document.getElementsByTagName('body')[0].className+=' cookiebanner';              
}      
// Retourne la chaine de caractère correspondant à nom=valeur
function getCookie(NomDuCookie)  {
  if (document.cookie.length > 0) {        
    var begin = document.cookie.indexOf(NomDuCookie+"=");
    if (begin != -1)  {
      begin += NomDuCookie.length+1;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) end = document.cookie.length;
      return unescape(document.cookie.substring(begin, end)); 
    }
  }
  return null;
}
// Fonction d'effacement des cookies   
function delCookie(name)   {
  var path = ";path=" + "/";
  var domain = ";domain=" + "."+document.location.hostname;
  var expiration = "Thu, 01-Jan-1970 00:00:01 GMT";       
  document.cookie = name + "=" + path + domain + ";expires=" + expiration;
}
// Efface tous les types de cookies utilisés par Google Analytics    
function deleteAnalyticsCookies() {
  var cookieNames = ["__utma","__utmb","__utmc","__utmz","_ga"]
  for (var i=0; i<cookieNames.length; i++) delCookie(cookieNames[i])
}
// La fonction d'opt-out   
function gaOptout() {
  document.cookie = disableStr + '=true;'+ getCookieExpireDate() +' ; path=/';       
  document.cookie = 'hasConsent=false;'+ getCookieExpireDate() +' ; path=/';
  var div = document.getElementById('cookie-banner');
  // Ci dessous le code de la bannière affichée une fois que l'utilisateur s'est opposé au dépôt
  // Vous pouvez modifier le contenu et le style
  if ( div!= null ) div.innerHTML = '<div style="background-color:#ffffff"> Vous vous êtes opposé \
  au dépôt de cookies de mesures d\'audience dans votre navigateur.</div>'
  window[disableStr] = true;
  deleteAnalyticsCookies();
}
function gaOptIn() {
  document.cookie = 'hasConsent=true; '+ getCookieExpireDate() +' ; path=/';
}
